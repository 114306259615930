.HeroSection {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.restricted {
    .mainContent {
      max-width: 770px;
      margin: auto;
    }
  }

  &.hero_overlay {
    content: "";
    position: absolute;
    background: rgba(255, 255, 255, 0.46);
    width: 100%;
    height: 100%;
  }

  .innerContainer {
    padding: 30px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    flex: 1;
    box-sizing: border-box;

    .mainContent {
      width: 100%;
    }
  }

  &.flex-end {
    .innerContainer {
      align-items: flex-end;
      padding-bottom: 0;
    }
  }
}
