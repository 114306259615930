@import url("https://fonts.googleapis.com/css2?family=Tomorrow:wght@400;600&display=swap");
@import "./variables.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "Tomorrow", sans-serif;
}

.text-center {
  text-align: center;
}

.header {
  height: 84px;
  z-index: 1;
  background: $primary-color; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    $secondary-color,
    $primary-color
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    $secondary-color,
    $primary-color
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  position: relative;

  @media (max-width: 500px) {
    padding: 0 10px;
  }

  .logo {
    width: 57px;
    height: 64px;
    background-size: contain;
    margin: auto;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    top: 6px;
  }

  .main_menu {
    line-height: 62px !important;
    margin-left: 58px !important;
    display: flex;
    background: none;
  }

  .ant-menu-submenu {
    padding: 0 !important;
  }

  .ant-menu-overflowed-submenu {
    margin-left: auto;
  }

  .hide-group-title {
    .ant-menu-item-group-title {
      display: none;
    }
  }
}

.frontpage_map {
  .ant-card-body {
    padding: 0;
  }
}

.map-responsive {
  overflow: hidden;

  padding-bottom: 56.25%;

  position: relative;

  height: 0;
}

.map-responsive iframe {
  left: 0;

  top: 0;

  height: 100%;

  width: 100%;

  position: absolute;
}

.contentCard {
  ul {
    margin-left: 30px;
  }

  li {
    list-style-type: initial;
  }
}

.ant-card-head {
  background: $primary-color;
  color: #fff;
}

.main_cards_frontpage {
  display: flex;
  justify-content: center;
  color: #fff;

  .ant-card {
    max-width: 300px;
    margin: 10px;
    width: 100%;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);

    .ant-card-head {
      background: inherit;
      color: inherit;
    }
  }
}

.ant-picker-calendar-mode-switch {
  display: none;
}

@media (max-width: 900px) {
  .main_cards_frontpage {
    flex-direction: column;
    align-items: center;
  }
}

.media_container {
  width: 750px;
  margin: auto;
}

@media (max-width: 800px) {
  .media_container {
    width: 500px;
  }
}

@media (max-width: 530px) {
  .media_container {
    width: 300px;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-top: 50px;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#social_icons a {
  margin: auto;
}

#social_icons img {
  height: 26px;
  margin: 8px;
}

.white-color {
  color: #fff !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}
