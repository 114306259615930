img {
  max-width: 100%;
}

.main_title_container {
  padding: 20px;
  padding-top: 50px;
  .mainTitle {
    padding-top: 20px;
    &.dadiTitle {
      font-size: 27px;
    }
  }

  img {
    max-width: 100%;
  }
}

#skrimsli_container {
  position: relative;
  max-width: 1200px;
  margin: auto;
  margin-top: 25vh;

  .skrimsli_head {
    position: absolute;
    left: 29.7%;
    top: -45%;
    width: 45.7%;

    animation-name: move_head;
    animation-duration: 10s;
    animation-iteration-count: infinite;
  }
}

@keyframes move_head {
  0% {
    transform: rotate(0);
  }

  15% {
    transform: rotate(-13deg);
  }
  30% {
    transform: rotate(-13deg);
  }

  45% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.darkSection {
  background: black;
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: #fff !important;
  }

  :not(ant-tabs-tab-active) {
    &.ant-tabs-tab-btn {
      color: white;
    }
  }
}

#bioContainer,
#lyricsContainer {
  max-width: 900px;
  margin: auto;
  text-align: justify;
}

.image_gallery {
  display: flex;
  grid-gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1600px;
  margin: auto;
}

footer {
  padding-bottom: 0 !important;
  background-image: linear-gradient(180deg, #020147fa, #341fb957) !important;
  background-color: black !important;
}

.white {
  color: white;
}

.staffTitle {
  font-size: 18px !important;
}

.imageWrapper {
  width: 20%;

  @media (max-width: 800px) {
    width: 40%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
}

.photoCredit {
  color: #bbb;
}
